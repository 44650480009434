<template>
    <b-row>
        <b-col cols="12">
            <!-- 1 -->
            <b-card no-body class="mb-4">
                <!-- Search Control -->
                <b-card-body class="p-3 d-flex justify-content-between">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-rule @click="titleModal = 'CREATE'"
                            >Add</b-button
                        >
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- Data Table -->
                <b-table
                    style="min-height: 250px"
                    show-empty
                    responsive
                    small
                    class="mb-0"
                    head-variant="light"
                    :items="rules"
                    :filter="filter"
                    :fields="fields"
                    hover
                >
                    <template #cell(id)="data"> #{{ data.item.id }} </template>
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Archived</b-badge>
                    </template>
                    <template #cell(action)="data">
                        <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                            <b-dropdown-item v-b-modal.modal-rule @click="editRules(data.item)">Edit</b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <!-- End Section -->

                <!-- Pagination -->
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            @change="getRules"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            @input="getRules"
                            v-model="currentPage"
                            :total-rows="totalRules"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
                <!-- End Section -->
            </b-card>
        </b-col>

        <!-- Modal -->
        <b-modal id="modal-rule" hide-footer centered :title="titleModal" @close="closeModal">
            <b-form @submit.prevent="createRule">
                <b-form-group label="Min Amount" label-class="fw-medium mb-1" label-for="minAmount">
                    <b-form-input
                        id="minAmount"
                        type="text"
                        placeholder="Min amount here"
                        required
                        v-model="minAmount"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Max Amount" label-class="fw-medium mb-1" label-for="maxAmount">
                    <b-form-input
                        id="maxAmount"
                        type="text"
                        placeholder="Max amount here"
                        required
                        v-model="maxAmount"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Percentage" label-class="fw-medium mb-1" label-for="percentage">
                    <b-form-input
                        id="percentage"
                        type="text"
                        placeholder="Percentage here"
                        required
                        v-model="percentage"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Months" label-class="fw-medium mb-1" label-for="maximumNumberOfMonths">
                    <b-form-input
                        id="maximumNumberOfMonths"
                        type="text"
                        placeholder="Months here"
                        required
                        v-model="maximumNumberOfMonths"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Loan type" label-class="fw-medium mb-1" label-for="loanType">
                    <b-form-select v-model="loanType" class="mb-3" required>
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option :value="loanType.id" v-for="loanType in loanstypes" :key="loanType.id">{{
                            loanType.description
                        }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-button block :disabled="loadingRule" variant="primary" type="submit">
                    <b-spinner small v-if="loadingRule"></b-spinner>
                    <span v-else>Save</span>
                </b-button>
            </b-form>
        </b-modal>

        <b-modal id="modal-remove" size="sm" hide-footer centered title="Delete">
            <b-card-text> Are you sure to want to remove this loan? </b-card-text>
            <div class="d-flex justify-content-end">
                <!-- <b-button variant="danger" class="mr-2" @click="removeRoles">Yes</b-button> -->
                <b-button variant="danger" class="mr-2">Yes</b-button>
                <!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
                <b-button variant="secondary">No</b-button>
            </div>
        </b-modal>
        <!-- End Section -->
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'rules',

    data: () => ({
        page: {
            title: 'rules',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'No',
                sortable: true,
            },
            {
                key: 'description',
                label: 'Loan type',
                sortable: true,
            },
            {
                key: 'minAmount',
                label: 'Min amount',
                sortable: true,
            },
            {
                key: 'maxAmount',
                label: 'Max amount',
                sortable: true,
            },
            {
                key: 'percentage',
                label: 'Percentage',
                sortable: true,
            },
            {
                key: 'maximumNumberOfMonths',
                label: 'Months',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Actions',
                sortable: false,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        titleModal: 'Create Loan',
        loadingRule: false,
        loanType: null,
        minAmount: '',
        maxAmount: '',
        percentage: '',
        maximumNumberOfMonths: '',
        rulesSelected: {},
    }),
    components: {},
    methods: {
        clearValues() {
            this.loanType = null;
            this.minAmount = '';
            this.maxAmount = '';
            this.percentage = '';
            this.maximumNumberOfMonths = '';
            this.rulesSelected = {};
        },
        closeModal() {
            this.isEditing = false;
            this.clearValues();
            this.titleModal = 'Create Rules';
        },
        async getData() {
            await this.getRules();
            await this.getTypes();
            await this.$store.dispatch('authorizations/get_allAuthorizations');
        },
        async getRules() {
            let data = {
                skip: this.currentPage,
                take: this.perPage,
            };
            await this.$store.dispatch('loan/rules/get_allRules', data);
        },
        async getTypes() {
            let data = {
                skip: 1,
                take: 9999,
            };
            await this.$store.dispatch('loan/types/get_allTypes', data);
        },
        async editRules(rules) {
            this.clearValues();
            const rulesData = await this.$store.dispatch('rules/get_rules', rules.id);
            this.rulesSelected = rulesData;
            this.isEditing = true;
            this.minAmount = rulesData.minAmount;
            this.maxAmount = rulesData.maxAmount;
            this.percentage = rulesData.percentage;
            this.maximumNumberOfMonths = rulesData.maximumNumberOfMonths;
            this.loanType = rulesData.loanTypeId; // brings the previous country value
            this.loanType = rulesData.loanTypeId; // copy the new country value
            this.titleModal = 'EDIT';
        },
        async createRule() {
            this.loadingRule = true;
            let data = {
                minAmount: this.minAmount,
                maxAmount: this.maxAmount,
                percentage: this.percentage,
                maximumNumberOfMonths: this.maximumNumberOfMonths,
                loanTypeId: this.loanType,
            };

            if (this.isEditing) {
                (data.id = this.rulesSelected.id), await this.$store.dispatch('loan/rules/update_rules', data);
            } else {
                await this.$store.dispatch('loan/rules/create_rules', data);
            }

            this.clearValues();

            await this.getRules();
            this.$bvModal.hide('modal-rule');
            this.loadingRule = false;
        },
    },
    computed: {
        ...mapGetters({
            rules: 'loan/rules/getRules',
            totalRules: 'loan/rules/getTotalRules',
            authorizations: 'authorizations/getAuthorizations',
            loanstypes: 'loan/types/getTypes',
        }),
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
